<template>
  <div class="dashboard-panel card" ref="container" v-bind:style="{'max-width':width || '100%'}">
    <div class="card-body">
      <div class="card-title">
        <h4 class="title-text">Parts Orders</h4>
        <div class="search grid">
          <div class="input-text" :class="{ 'input-text-mobile': isMobile() && isPartsCoordinator }">
            <search-filter
                type="estimates"
                v-model="searchFilter"
                :filters="computedSearchFilters"
                :filterValue="filter"
                :limit-length="isSupplierFilterActive ? 10 : 50"
                :isMyltipleOptions="true"
                :additionalMenu="additionalMenu"
                @selectAdditional="redirectToRfqView"
                @onEnter="onEnter"
                :isMyltiple="true"
                :optionsLength="5"
                @change="onSearchFilterChange"
                @selectedToken="selectedToken"
                @updateToken="value => $store.commit('repairer/order/setToken', value)"
                @updateTokens="value => $store.commit('repairer/order/setTokens', value)"
                :debug="false"
                height="41px"
                aria-expanded="false"
                data-toggle="dropdown"
            />
          </div>
          <!--                    <a @click.prevent="submitOrder" class="btn btn-primary" style="vertical-align: middle; cursor: pointer">-->
          <!--                        <i class="bx bx-plus" style="font-size: 17px; vertical-align: middle;"></i>-->
          <!--                        <span style="vertical-align: middle;">Submit Order</span>-->
          <!--                    </a>-->
        </div>
      </div>
      <div class="cards-list">
        <div class="card-item">
          <div class="header-item">
            Ordered
            <i @click="goToOrders({tab: 'Current'})" class="bx bx-link-external clickable"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div class="list-values-header">
                <span @click="goToOrders({tab: 'Current'})" class="numb clickable">{{ ordersOrderedCount }}</span>
                New Orders
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="header-item">
            On Back-Order
            <i @click="goToOrders({tab: 'BackOrder', filter: 'Back Orders'})" class="bx bx-package text-success clickable"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div class="list-values-header">
                <span @click="goToOrders({tab: 'BackOrder', filter: 'Back Orders'})" class="numb clickable">{{ ordersOnBackOrderCount }}</span>
                Back Orders
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="header-item">
            Shipped
            <i @click="goToOrders({tab: 'Current', filter: 'Shipped'})" class="bx icon-truck-transit text-primary clickable" style="color:#2EA9FF"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div class="list-values-header">
                <span @click="goToOrders({tab: 'Current', filter: 'Shipped'})" class="numb clickable">{{ ordersShippedCount }}</span>
                Orders Shipped
              </div>
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="header-item">
            Delivered
            <i @click="goToOrders({tab: 'Completed'})" class="bx bx-buildings clickable"></i>
          </div>
          <div class="body-item">
            <div class="list-values">
              <div class="list-values-header">
                <span @click="goToOrders({tab: 'Completed'})" class="numb clickable">{{ ordersDeliveredCount }}</span>
                Parts Order Delivered
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 v-if="!(isMobile() && isPartsCoordinator)" class="title-text chart-name">
        Parts Orders - <small>{{ this.showChartBy.label }}</small>

        <b-dropdown right text="Options" variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <i class="bx bx-chevron-down text-black"></i>
          </template>
          <b-dropdown-item @click="showByMonth">
            by Month
          </b-dropdown-item>
          <b-dropdown-item @click="showByWeek">
            by Week
          </b-dropdown-item>
        </b-dropdown>

      </h4>
      <div v-if="!(isMobile() && isPartsCoordinator)" id="chart-area"></div>
    </div>
  </div>
</template>

<style scoped>
@import url(../../../../styles/dashboard/style.css);


.input-text-mobile >>> .search-filter .search-filter__box{
  border: 2px solid #03a3ae !important;
}
.input-text-mobile >>> .search-filter__box .search-filter__li i{
  color: #03a3ae !important;
}
.input-text-mobile >>> .search-filter__input::placeholder{
  color: #03a3ae !important;
}
.input-text-mobile{
  margin-right: 0 !important;
}
.input-text-mobile >>> .dropdown .custom-dropdown{
  width: calc(100% + 60px);
  left: -45px;
}
.chart-name {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.chart-name i {
  font-size: 32px;
  cursor: pointer;
}

.chart-name small {
  line-height: 22px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.icon-truck-transit {
  mask-image: url(../../../../assets/img/icons8-in-transit-50.svg);
  -webkit-mask-image: url(../../../../assets/img/icons8-in-transit-50.svg);
  mask-size: contain;
  -webkit-mask-size: contain;
  height: 24px;
  width: 24px;
  background-color: #63BCFF;
}
</style>

<script>
import chart from "tui-chart";
import "tui-chart/dist/tui-chart.css";
import Axios from "axios";
// import moment from "moment";
import accounting from "accounting";
import SearchFilter from '../../../../components/utility/search-filter/search-filter'
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {isMobile} from "mobile-device-detect";

export default {
  name: "PartsOrders",
  props: {
    width: String,
    height: String
  },
  components: {
    SearchFilter
  },
  mounted() {
    // this is bad
    const menuBtn = document.querySelector(".nav-link.toggle-button");
    if (menuBtn) {
      menuBtn.addEventListener("click", () => {
        this.resizeChart();
      });
    }

    window.addEventListener("orientationchange", () => {
      this.resizeChart();
    });

    this.getChartData();
  },
  data() {
    return {
      testData: {
        credits: {},
        categories: [],
        series: [
          {
            name: "Orders",
            data: []
          }
        ]
      },
      tokens: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
      },
      searchFilter: '',
      filter: '',
      searchFilterObj: {
        search: '',
      },
      yAxisMax: 80000,
      ordersOrderedCount: 0,
      ordersOnBackOrderCount: 0,
      ordersShippedCount: 0,
      ordersDeliveredCount: 0,
      space: 16,
      ordersChart: null,
      showChartBy: {
        type: 'month',
        label: 'by Month'
      },
      chartData: {
        month: {
          credits: {},
          categories: ['01-01-2020', '01-02-2020'],
          series: [
            {
              name: "Orders",
              data: [20000, 30000]
            }
          ],
          maxY: 100,
        },
        week: {
          credits: {},
          categories: [],
          series: [
            {
              name: "Orders",
              data: [5000, 7000]
            }
          ],
          maxY: 100,
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      gettersAllOrders: 'repairer/order/getAllOrders',
      isPartsCoordinator: 'currentUser/isPartsCoordinator',
    }),
    additionalMenu() {
      let data = _.filter(this.gettersAllOrders, (r) => {
        return _.startsWith(_.trim(_.toLower(r.number)), _.trim(_.toLower(this.searchFilter)))
      })
      let result = [];
      _.forEach(data, (itm) => {
        let label = itm.number
        if (itm.supplierName) {
          label +=  ' - '
        }

        if (itm.supplierName) {
          label += itm.supplierName + ' '
        }
        result.push({
          value: itm.id,
          label: label,
          number: itm.number,
          rfqId: itm.rfqId,
          jobViewId: itm.jobViewId
        })
      })
      result = _.orderBy(result, ['label'], ['asc']).slice(0, 5)
      let addedJobViewIds=[]
      let jobViewOptions = []
      _.forEach(result, (r) => {
            if (!_.includes(addedJobViewIds, r.jobViewId)) {
              let l = 'JOB VIEW'
              if (r.number) {
                l = r.number + ' - JOB VIEW'
              }

              if (r.rfqId != r.jobViewId) {
                if (result.length == 1) {
                  jobViewOptions.push({
                    value: r.rfqId,
                    label: l,
                    isJobView: true
                  })
                  addedJobViewIds.push(r.jobViewId)
                }
              } else {
                jobViewOptions.push({
                  value: r.rfqId,
                  label: l,
                  isJobView: true
                })
                addedJobViewIds.push(r.jobViewId)
              }
            }
      })
      _.forEach(jobViewOptions, (j) => {
        let index = _.findIndex(result, (r) => {
          return Number(r.jobViewId) === Number(j.value)
        })
        if (index !== -1) {
          result.splice(index, 0, j)
        }
      })
      return result
    },
    computedSearchFilters () {
      let makes = {}
      let partNumbers = {}
      let suppliers = {}
      let invoiceNbrs = {}
      let data = this.gettersAllOrders.slice()

      data.forEach(rfq => {
        if (rfq.partsNumbers) {
          let numbers = rfq.partsNumbers.split(';')
          _.forEach(numbers, n => {
            partNumbers[n.toLowerCase().trim()] = { label: n.toLowerCase().trim() }
          })
        }

      })

      data.forEach(rfq => {
        if (rfq.supplierName) {
          suppliers[rfq.supplierName.trim().toLowerCase()] = { label: rfq.supplierName.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.make) {
          makes[rfq.make.trim().toLowerCase()] = { label: rfq.make.trim() }
        }
      })

      data.forEach(rfq => {
        if (rfq.invoiceNumber) {
          invoiceNbrs[rfq.invoiceNumber.trim().toLowerCase()] = { label: rfq.invoiceNumber.trim() }
        }
      })

      suppliers = _.orderBy(Object.values(suppliers), ['label'], ['asc'])
      invoiceNbrs = _.orderBy(Object.values(invoiceNbrs), ['label'], ['asc'])
      partNumbers = _.orderBy(Object.values(partNumbers), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      makes = _.orderBy(Object.values(makes), [(itm) => itm.label.toLowerCase().trim()], ['asc'])
      let statusOptions = [
        { label: 'Received' },
        { label: 'Incorrect Part Delivered' },
        { label: 'Incorrect Part Ordered' },
        { label: 'Damaged Part in Transport' },
        { label: 'Damaged Part EX Warehouse' },
        { label: 'Cancelled Part' },
        { label: 'Not Received' },
      ]

      // Create searchFilter filters
      return [
        {
          id: 'partNumber',
          label: 'Part Number',
          iconClass: 'bx-user',
          options: [
            partNumbers,
          ]
        },
        {
          id: 'make',
          label: 'Make',
          iconClass: 'bx-user',
          options: [
            makes,
          ]
        },
        {
          id: 'invoiceNumber',
          label: 'Invoice Nbr',
          iconClass: 'bx-user',
          options: [
            invoiceNbrs,
          ]
        },
        {
          id: 'supplier',
          label: 'Supplier',
          iconClass: 'bx-user',
          options: [
            suppliers
          ]
        },
        {
          id: 'status',
          label: 'Status',
          iconClass: 'bx-user',
          options: [
            statusOptions
          ]
        },
      ]
    },
    isSupplierFilterActive () {
      for (let key in this.searchFilterObj) {
        if (this.searchFilterObj[key].supplier) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      setFilter: 'repairer/order/setFilter'
    }),
    redirectToRfqView(itm) {
      this.setFilter('')
      if (itm.isJobView) {
        this.$router.push({name: 'RepairerJobView', params: {job_id: itm.value}});
      } else {
        this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: itm.value}});
      }
    },
    onEnter() {
      setTimeout(() => {
        let order = _.find(this.additionalMenu, (r) => {
          return r.isJobView
        })
        if (order && !_.isEmpty(this.searchFilter)) {
          this.setFilter('')
          this.$router.push({name: 'RepairerJobView', params: {job_id: order.value}});
          //this.$router.push({name: 'RepairerPartsOrderView', params: {orderId: order.id}});
        } else {
          this.$router.push({name: 'RepairerPartsOrders'}).catch(err => err)
        }
      }, 500)
    },
    goToOrders(v) {
      this.$router.push({name: 'RepairerPartsOrders', params: v});
    },
    selectedToken () {
      this.$router.push({name: 'RepairerPartsOrders'});
    },
    onSearchFilterChange (filters) {
      this.$store.commit('repairer/order/setSearchFilterObj', filters)
      if (filters[0] && filters[0].search) {
        this.setFilter(filters[0].search)
      } else {
        this.setFilter('')
      }
      console.log('onSearchFilterChange', filters);
    },
    submitOrder() {
      this.$router.push({
        name: 'RepairerRFQAdd', params: {
          isSentAsDirectOrder: true,
        }
      });
    },
    openRouteWithHash(hash) {
      this.$router.push({name: 'SuppliersPartsOrders', hash: '#' + hash})
    },
    setShowChartBy(type) {
      let result = {
        type: 'month',
        label: 'by Month'
      };
      if (type === 'week' && !_.isEmpty(this.chartData.week.categories)) {
        result = {
          type: 'week',
          label: 'by Week'
        };
      }
      this.createChart(result.type);
      this.showChartBy = result;
    },
    setChartDataByKey(chartData = {}, chartKey = '') {
      let categories = [],
          data = [];
      _.forEach(chartData[chartKey].netSales, (value, key) => {
        categories.push(key);
        data.push(value);
      });
      this.chartData[chartKey].credits = chartData[chartKey].credits;
      this.chartData[chartKey].categories = categories;
      this.chartData[chartKey].series[0].data = data;
    },
    getChartData() {
      NProgress.start();
      Axios.get('/ir/repairer/dashboard').then(response => {
        console.log('response.data', response.data);
        this.setChartDataByKey(response.data, 'month');
        this.setChartDataByKey(response.data, 'week');

        this.chartData = response.data.testChartData;

        this.createChart();
        this.ordersOrderedCount = response.data.countOrdered;
        this.ordersOnBackOrderCount = response.data.countOnBackOrder;
        this.ordersShippedCount = response.data.countShipped;
        this.ordersDeliveredCount = response.data.countDelivered;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        NProgress.done();
      });
    },
    resizeChart() {
      if (!this.$refs.container) return;
      setTimeout(() => {
        this.ordersChart.resize({
          width: this.$refs.container.clientWidth - this.space,
          height: this.height
        });
      }, 100);
    },
    createChart(type = 'month') {
      const container = document.getElementById("chart-area");
      let data = (type === 'month') ? this.chartData.month : this.chartData.week;

      let credits = _.map(data.credits, (item, key) => {
        return {
          date: key,
          value: item
        }
      });

      const options = {
        chartExportMenu: {
          visible: false
        },
        tooltip: {
          offsetX: 1,
          offsetY: -15,
          template: (category, item) => {
            let creditData = _.find(credits, credit => {
              return credit.date === category
            });

            let head =
                    '<div class="tt-title">' + category + "</div>",
                body =
                    `<div  class="tt-body">
                            <ul>
                            <li><span style="white-space: nowrap; font-weight: bold;">Orders Placed &nbsp;</span><span class="tt-value">` +
                    accounting.formatMoney(item.value) +
                    `</span></li>
                            <li><span style="white-space: nowrap; font-weight: bold;">Credits Raised &nbsp;</span><span class="tt-value">` +
                    accounting.formatMoney((!_.isEmpty(creditData)) ? creditData.value : 0)
                    + `</span><li>
                            </ul>
                            </div>`;
            return (
                '<div class="tooltip-template">' +
                head +
                body +
                '<div class="point"></div></div>'
            );
          }
        },
        chart: {
          width: this.$refs.container.clientWidth - this.space,
          height: this.height || 304,
          format: "1,000"
        },
        yAxis: {
          title: "",
          prefix: "$",
          min: 0,
          max: data.maxY,
        },
        xAxis: {
          title: "&nbsp;",
          pointOnColumn: true
        },
        legend: {
          visible: false
        },
        series: {
          barWidth: 30
        }
      };
      const theme = {
        chart: {
          fontFamily: "Inter",
          fontSize: "16px"
        },
        series: {
          colors: ["#FFCFB1"],
          width: "50px"
        },
        xAxis: {
          tickColor: "rgba(27,30,56,0.1)",
          label: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: "bold",
            color: "#1C1F39"
          }
        },
        yAxis: {
          tickColor: "rgba(27,30,56,0.1)",
          label: {
            fontSize: 14,
            fontFamily: "Inter",
            fontWeight: "bold",
            color: "#1C1F39"
          }
        },
        plot: {
          lineColor: "rgba(27,30,56,0.1)",
          background: "#efefef"
        }
      };

      chart.registerTheme("myTheme", theme);
      options.theme = "myTheme";

      container.innerHTML = '';
      this.ordersChart = chart.columnChart(container, data, options);

      window.addEventListener("resize", () => {
        this.resizeChart();
      });
    },
    showByMonth() {
      this.setShowChartBy('month');
    },
    showByWeek() {
      this.setShowChartBy('week');
    },
    isMobile() {
      return isMobile
    },
  }
};
</script>

<style>
.dashboard-page .tui-chart .tui-chart-tooltip-area .tooltip-template {
  width: auto;
}
</style>
