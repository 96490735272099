<template>
  <div
      class="wrapper"
      id="app-wrapper"
      :class="[activeMainTemplateClass, {'is-visible': isLogin || isHome}]"
      v-show="isLoaded"
      :style="{backgroundColor: !isLoggedIn? '#212330':''}"
  >

    <AuthByCode v-if="isAuthByCode && !isRegisterOrLoginPage && !isActivateExtensionPage && !isSpecialOrder"></AuthByCode>
    <template v-else-if="isLoggedIn && !isHome && !isLogin && !isLogoutAction  && !isRegisterOrLoginPage && !isActivateExtensionPage && !isSpecialOrder">
      <SiteSidebar></SiteSidebar>
      <SiteHeader></SiteHeader>
      <SiteContent></SiteContent>
    </template>

    <RouterContainer v-else-if="(!isLoggedIn && !isLogoutAction) || isRegisterOrLoginPage || isActivateExtensionPage || isSpecialOrder"></RouterContainer>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { mapGetters } from 'vuex'
import accounting from 'accounting'

import SiteHeader from './components/common/site-header'
import SiteSidebar from './components/common/site-sidebar'
import SiteContent from './components/common/site-content'
import AuthLoginPage from './views/auth/login'
import ForgotPage from './views/auth/default/forgot.vue'
import ForgotUserDataPage from './views/auth/default/forgot-userdata.vue'
import PasswordResetPage from './views/auth/password'
import ForgotPasswordPage from './views/auth/default/forgot_pass.vue'
import ForgotPassUserDataPage from './views/auth/default/forgot_pass-userdata.vue'
import RouterContainer from './views/container'
import ToastTemplate from './components/toastTemplate'
import AuthByCode from './views/auth/by-code'
import Axios from 'axios'
import moment from 'moment'


import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

import { createToastInterface } from "vue-toastification";
import "vue-toastification/dist/index.css";
import newRequestForQuoteSound from './assets/audio/new-request-for-quote-sound.wav';
import updatePricedQuoteRequestSound from './assets/audio/update-priced-quote-request-sound.wav';
import assignedToUserSound from './assets/audio/assigned-to-user-sound.mp3';
import updatedQuotePricingReceivedSound from './assets/audio/updated-quote-pricing-received.mp3'
import stockStatusChangeSound from './assets/audio/stock-status-change-sound.mp3';
import {isMobileOnly} from "mobile-device-detect";
import dayjs from "dayjs";

export default {
  name: 'app',
  components: {
    SiteHeader,
    SiteSidebar,
    SiteContent,
    RouterContainer,
    AuthByCode,
  },
  data: function () {
    return {
      isLoaded: false,
      isUpdate: null,
      customToast: null,
      toastContent: {},
      toastOptionsOrange: {
        toastClassName: "my-custom-toast-class-orange",
        timeout: false,
        closeButton: false,
        icon: false,
      },
      toastOptions: {
        toastClassName: "my-custom-toast-class",
        timeout: false,
        closeButton: false,
        icon: false,
      },
      toastOptionsOrderCancelled: {
        toastClassName: "my-custom-toast-class my-custom-toast-order-cancelled",
        timeout: false,
        closeButton: false,
        icon: false,
      },
      toastOptionsRed: {
        toastClassName: "my-custom-toast-class-red",
        timeout: false,
        closeButton: false,
        icon: false,
      },
      toastOptionsBlue: {
        toastClassName: "my-custom-toast-class-blue",
        timeout: false,
        closeButton: false,
        icon: false,
      },
      toastOptionsBlueBorder: {
        toastClassName: "my-custom-toast-class-blue-bodrer",
        timeout: false,
        closeButton: false,
        icon: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      activeMainTemplateClass: 'getActiveMainTemplateClass',
      isLoggedIn: 'auth/isLoggedIn',
      isLogoutAction: 'auth/isLogoutAction',
      isSoundNoticeNewRfq: 'currentUser/isSoundNoticeNewRfq',
      isSoundNoticeUpdatedPriceRequest: 'currentUser/isSoundNoticeUpdatedPriceRequest',
      isPlayNotificationSound: 'currentUser/isPlayNotificationSound',
      isSoundNoticeQuoteAssigned: 'currentUser/isSoundNoticeQuoteAssigned',
      currentUser: 'currentUser/getCurrentUserInfo',
      toastShowCount: 'toastShowCount',
      toastSettings: 'toastSettings',
    }),
    isAuthByCode() {
      return !!(this.$route && this.$route.params && this.$route.params.authCode && this.$route.params.authCode != '');
    },
    isHome () {
      return this.$route.path == '/'
    },
    isLogin () {
      return this.$route.path == '/login'
    },
    isRegisterOrLoginPage () {
      return this.$route.path.includes('/register') || this.$route.path.includes('/auth') || this.$route.path.includes('/reset-password') || this.$route.path === '/login'
    },
    isActivateExtensionPage () {
      return this.$route.path.includes('/partsbridge/activate')
    },
    isSpecialOrder () {
      return this.$route.path.includes('/public/special-order/')
    },
    isForgot () {
      return this.$router.history.current['path'].indexOf('forgot1') !== -1
    },
    isForgotUserData () {
      return this.$router.history.current['path'].indexOf('forgot2') !== -1
    },
    isResetPass () {
      return this.$router.history.current['path'].indexOf('reset-pass') !== -1
    },
    isForgotPass1 () {
      return (
          this.$router.history.current['path'].indexOf('forgot-pass1') !== -1
      )
    },
    isForgotPass2 () {
      return (
          this.$router.history.current['path'].indexOf('forgot-pass2') !== -1
      )
    },
    isInviteCustomer () {
      return (
          this.$router.history.current['path'].indexOf('invite-customer') !== -1
      )
    },
    isRegisterRepairerSupplier () {
      return (
          this.$router.history.current['path'].indexOf('invite-repairer') !== -1 ||
          this.$router.history.current['path'].indexOf('/register/repairer/') !== -1 ||
          this.$router.history.current['path'].indexOf('/register/supplier/') !== -1
      )
    },
    isRegister () {
      return (
          this.$router.history.current['path'].indexOf('repairer/register') !== -1
      )
    },
    isSupplierRegister () {
      return (
          this.$router.history.current['path'].indexOf('supplier/register') !== -1
      )
    }
  },
  methods: {
    getOpenTime(seconds) {
      seconds = Number(seconds);
      if (seconds < 0) {
        return 'Finished'
      } else if (seconds === 0) {
        return 'Not Started'
      }

      let d = Math.floor(seconds / (3600*24));
      let h = Math.floor(seconds % (3600*24) / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 60);

      let dDisplay = d > 0 ? d + ' D' : "";
      let hDisplay = h > 0 ? h + ' Hrs' : "";
      let mDisplay = m > 0 ? m + ' Mins' : "";
      // let sDisplay = s > 0 ? s + ' S' : "";
      let sDisplay = "";

      if (dDisplay && (hDisplay || mDisplay || sDisplay)) {
        dDisplay += ' - '
      }
      if (hDisplay && (mDisplay || sDisplay)) {
        hDisplay += ' - '
      }
      if (mDisplay && sDisplay) {
        mDisplay += ' - '
      }

      return (dDisplay + hDisplay + mDisplay + sDisplay) || '0 Mins';
    },
      logout() {
      NProgress.start();
      let wrapper = document.getElementById('app-wrapper');
      wrapper.style.opacity = 0;
      setTimeout(() => {
        this.$store.dispatch('auth/logout')
      }, 500)
    },
    loadNotices(timestamp){
      // if (timestamp <= 0) {
      //   return;
      // }

      Axios.get(`/ir/notices/${timestamp}`)
          .then(response => {
            if(response.data._status){
              _.forEach(response.data.notices, (notice, ind) => {
                setTimeout(() => {
                  this.toastContent.props = {
                    toastText: notice.text,
                    toastTitle: notice.title,
                    id: notice.entityId,
                    type: notice.entityType,
                    toastTime: _.includes(notice.title, 'UPDATE RFQ requested') ? this.getOpenTime(notice.linkText) : null,
                    isUserTypeSupplier: this.$store.getters['currentUser/isUserTypeSupplier'],
                  }

                  if (notice.entityType == 'BestPriceRequest') {
                    this.toastContent.props.toastText += dayjs(notice.linkUrl).format('h:mm A') + ' ' + '<span class="toast-text-ancillary">Deal Value ' + accounting.formatMoney(notice.linkText) + '</span>'
                  }

                  let toastContent = _.cloneDeep(this.toastContent)

                  if (notice.title === 'Order changes') {
                    this.customToast(toastContent, this.toastOptions);
                    let audio = new Audio(stockStatusChangeSound);
                    let interval = setInterval(() => {
                      audio.play()
                          .then(() => {
                            clearInterval(interval)
                          })
                          .catch(e => {
                             console.log('audio.catch:', e)
                          })
                    }, 100)
                  }else if (notice.title === 'Updated quote pricing received') {
                    this.customToast(toastContent, this.toastOptions);
                    let audio = new Audio(updatedQuotePricingReceivedSound);
                    let interval = setInterval(() => {
                      audio.play()
                          .then(() => {
                            clearInterval(interval)
                          })
                          .catch(e => {
                            // console.log(e, 'e')
                          })
                    }, 1000)
                  }  else if (notice.title === 'Quote Assigned') {
                    if (this.isSoundNoticeQuoteAssigned) {
                      this.customToast(toastContent, this.toastOptions);
                      let audio = new Audio(assignedToUserSound);
                      let interval = setInterval(() => {
                        audio.play()
                            .then(() => {
                              clearInterval(interval)
                            })
                            .catch(e => {
                              // console.log(e, 'e')
                            })
                      }, 1000)
                    }
                  } else {
                    if (_.includes(notice.title, 'Order Cancellation')) {
                      this.customToast(toastContent, this.toastOptionsOrderCancelled);
                    } else if (_.includes(notice.title, 'New Delivery ETA')) {
                      this.customToast(toastContent, this.toastOptionsOrange);
                    } else if (_.includes(notice.title, 'UPDATE RFQ requested')) {
                      this.customToast(toastContent, this.toastOptionsOrange);
                    } else if (_.includes(notice.title, 'Back Order Acceptance')) {
                      this.customToast(toastContent, this.toastOptionsRed);
                    } else if (_.includes(notice.title, 'Back Order Accept Rcvd')) {
                      this.customToast(toastContent, this.toastOptionsOrderCancelled);
                    } else if (notice.entityType == 'BestPriceRequest') {
                      if (this.isSoundNoticeUpdatedPriceRequest) {
                        let audio = new Audio(updatedQuotePricingReceivedSound);
                        let interval = setInterval(() => {
                          audio.play()
                              .then(() => {
                                clearInterval(interval)
                              })
                              .catch(e => {
                                // console.log(e, 'e')
                              })
                        }, 1000)
                      }
                      this.customToast(toastContent, this.toastOptionsBlueBorder);
                    } else {
                      this.customToast(toastContent, this.toastOptions);
                    }

                    let audio = new Audio(updatePricedQuoteRequestSound);

                    if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                      if (this.isSoundNoticeUpdatedPriceRequest && notice.title === 'Pricing update received') {
                        let interval = setInterval(() => {
                          audio.play()
                              .then(() => {
                                clearInterval(interval)
                              })
                              .catch(e => {
                                // console.log(e, 'e')
                              })
                        }, 1000)
                      }
                    } else {
                      if (this.isSoundNoticeUpdatedPriceRequest && (_.includes(notice.title, 'UPDATE RFQ requested'))) {
                        let interval = setInterval(() => {
                          audio.play()
                              .then(() => {
                                clearInterval(interval)
                              })
                              .catch(e => {
                                // console.log(e, 'e')
                              })
                        }, 1000)
                      }
                    }
                  }
                }, 100 + 500 * ind);
              })
            }
          })
          .catch(e => console.error(e))
    },
    checkUpdate () {
      if (this.$store.getters['auth/isLoggedIn']) {
        let timestamp = this.$store.getters['updater/lastTimestamp']
        //console.log('update=', timestamp);
        Axios.get('/ir/update/' + timestamp)
            .then(response => {
              if (response.data && response.data._status) {
                this.$store.dispatch('updater/setLastTimestamp', response.data._timestamp, { root: true })
                if (response.data.update) {
                  if (response.data.update.isNeedLogout) {
                    this.logout();
                  }

                  if (response.data.update.rfq && response.data.update.rfq > 0) {
                    if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                      this.$store.dispatch('repairer/rfq/init', response.data.update.timestamp, { root: true })
                    }
                    if (this.$store.getters['currentUser/isUserTypeSupplier']) {
                      this.$store.dispatch('supplier/rfq/init', response.data.update.timestamp, { root: true })
                    }
                  }

                  if (response.data.update.noticesCount > 0) {
                    this.loadNotices(response.data.update.timestamp);
                  }

                  if (response.data.update.needForceDataRefresh) {
                    this.$store.dispatch('currentUser/loadUserInfo')
                    this.$store.dispatch('shortUpdating')
                  }

                  if (response.data.update.quoteNumberCount > 0) {
                    this.$store.dispatch('supplier/rfq/getQuoteNbrs', response.data.update.timestamp, { root: true })
                  }

                  if (response.data.update.needQuoteNumbersRefresh) {
                    this.$store.dispatch('supplier/rfq/getQuoteNbrs')
                  }

                  if (response.data.update.rfqToSupplierCurrentlyViewing) {
                    this.$store.commit('supplier/rfq/setViewingUsers', response.data.update.rfqToSupplierCurrentlyViewing)
                  }
                    if (response.data.update.credits && response.data.update.credits > 0) {
                    // console.log('Credits timestamp', response.data.update.timestamp)
                    if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                      this.$store.dispatch('repairer/credit/init', response.data.update.timestamp, { root: true })
                    }
                    if (this.$store.getters['currentUser/isUserTypeSupplier']) {
                      this.$store.dispatch('supplier/credit/init', response.data.update.timestamp, { root: true })
                    }
                  }

                  if (response.data.update.orders && response.data.update.orders > 0) {
                    if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                      this.$store.dispatch('repairer/order/init', {
                        timestamp: response.data.update.timestamp,
                        isFromUpdate: true,
                      }, { root: true })
                    }
                    if (this.$store.getters['currentUser/isUserTypeSupplier']) {
                      this.$store.dispatch('supplier/order/init', response.data.update.timestamp, { root: true })
                    }
                  }

                  if (response.data.update.customerCount > 0) {
                    this.$store.dispatch('supplier/customers/init', response.data.update.timestamp, { root: true })
                  }

                  if (response.data.update.suppliers && response.data.update.suppliers > 0) {
                    if (this.$store.getters['currentUser/isUserTypeRepairer']) {
                      this.$store.dispatch('repairer/supplier/init', response.data.update.timestamp, { root: true })
                    }
                  }

                  if (response.data.update.users && response.data.update.users > 0) {
                    this.$store.dispatch('users/fetchAllUsers', {}, {root: true})
                  }

                  if ((response.data.update.company && response.data.update.company > 0) || response.data.update.isCompanyChanged) {
                    this.$store.dispatch('currentCompany/loadCompanyInfo', {}, {root: true})
                  }
                }
              }
            })
      }
    }
  },
  created: function () {
    if (!this.isHome && !this.isLoggedIn && !this.$route.path.includes('/register') && !this.$route.path.includes('/auth') && this.$route.path !== '/login' && !this.$route.path.includes('/reset-password')) {
      this.$router.push('/')
    }

    let vm = this
    this.$http.interceptors.response.use(undefined, function (err) {
      // console.log('ERROR:', err, err.response);
      return new Promise(function (resolve, reject) {
        if (err.response.status === 401) {
          NProgress.start();
          let wrapper = document.getElementById('app-wrapper');
          wrapper.style.opacity = 0;
          setTimeout(() => {
            vm.$store.dispatch('auth/logout')
          }, 500)
        }
        throw err
      })
    })

    if (this.$store.getters['auth/isLoggedIn']) {
      this.$store.dispatch('initAppAfterLogin')
      if (this.isHome || this.isLogin) {
        this.$router.push('/s').catch(err => {})
      }
      let interval = setInterval(() => {
        if (this.currentUser.id) {
          clearInterval(interval);
          if (this.$store.getters['currentUser/isUserTypeSupplier']) {
            if (this.$route.path == '/r' || this.$route.path.includes('/r/')) {
              this.$router.push('/s').catch(err => {})
            }
          } else {
            if (this.$route.path == '/s' || this.$route.path.includes('/s/')) {
              this.$router.push('/r').catch(err => {})
            }
          }
        }
      }, 10)
    }
    if (this.activeMainTemplateClass || !this.isLoggedIn) this.isLoaded = true

    //  console.log(vm.isUpdate);
    clearInterval(vm.isUpdate)
    if (!vm.isUpdate) {
      this.isUpdate = setInterval(() => {
        vm.checkUpdate()
      }, 5000)
    }

    if (document.body && isMobileOnly) {
      document.body.classList.add('ps-mobile-view')
    }
  },
  watch: {
    toastShowCount: {
      handler(v) {
        if (v) {
          let toastContent = _.cloneDeep(this.toastSettings.toastContent)
          toastContent.listeners = {
            dismissAll: () => this.customToast.clear()
          }
          if (this.customToast) {
            this.customToast(toastContent, this.toastSettings.toastOptions);
          }
        }
      },
      immediate: true,
    },
    activeMainTemplateClass (val) {
      if (val) this.isLoaded = true
    },
    isPlayNotificationSound: {
      handler (val) {
        if (val) {
          let audio = new Audio(newRequestForQuoteSound);
          let interval = setInterval(() => {
            audio.play()
                .then(() => {
                  clearInterval(interval)
                })
                .catch(e => {
                  // console.log(e, 'e')
                })
          }, 1000)
        }
      },
      immediate: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isLoaded = true
    })

    this.toastContent = {
      component: ToastTemplate,
      props: {
        toastText: '',
        toastTitle: '',
        id: '',
        type: '',
        isUserTypeSupplier: false
      },
      listeners: {
        dismissAll: () => this.customToast.clear()
      }
    }
    this.customToast = createToastInterface(this.toastOptions)
  }
}
Vue.filter('formatMoney', function (value) {
  return accounting.formatMoney(value)
})
Vue.filter('formatDate', function (value, format = 'DD/MM/YYYY') {
//  console.log('formatDate', value, format)
  if (!value) {
    return ''
  }
  return moment(value).format(format)
})
Vue.filter('striphtml', function (value) {
  var div = document.createElement('div')
  div.innerHTML = value
  var text = div.textContent || div.innerText || ''
  return text
})
</script>

<style lang="scss">
@import "./styles/style-views";
@import './styles/style-components';
</style>

<style>
@import url("../node_modules/@syncfusion/ej2-base/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-buttons/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-dropdowns/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-inputs/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-navigations/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-popups/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-lists/styles/material.css");
@import url("../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css");
</style>


<style>
.ps-mobile-view #hubspot-messages-iframe-container {
  display: none !important;
}

.Vue-Toastification__toast-component-body .primary-color {
  color: #007bff;
  cursor: pointer;
  font-weight: 700;
}

.Vue-Toastification__toast-component-body .primary-color:hover {
  color: #0056b3;
}
.Vue-Toastification__toast{
  width: 600px;
}

.Vue-Toastification__container {
  z-index: 99999999999;
}
.my-custom-toast-class {
  background: linear-gradient(135deg, #23a6ab 0%, #1ba9af 49.12%, #29BBC1 100%);
  font-size: 16px;
}
.my-custom-toast-class-red{
  background-color: #FF2524;
}
.my-custom-toast-class-red .toast-text{
  font-size: 13px;
  font-weight: 700;
}
.my-custom-toast-class-red .toastr-title-span{
  font-size: 14px;
  font-weight: 800;
}
.my-custom-toast-class-red .toast-company-name{
  font-size: 14px;
}
.my-custom-toast-class-red .dismiss-all{
  color: white;
}
.my-custom-toast-class-orange{
  background-color: #EB7C31FF;
  height: 150px;
}
.my-custom-toast-class-orange .primary-color {
  color: white;
}
.my-custom-toast-class-orange .primary-color:hover {
  color: white;
}
.my-custom-toast-class-orange .mt-1{
  margin-top: 8px !important;
}
.my-custom-toast-class-orange .toast-rfq-num span{
  font-size: 15px;
  font-weight: bold;
}
.my-custom-toast-class-orange .toast-time span{
  font-size: 15px;
  /*font-weight: bold;*/
}
.my-custom-toast-class-orange .toast-header-wrapper span{
  font-size: 15px;
  font-weight: 900;
}
.my-custom-toast-class-orange .toast-header-buttons .dismiss-all{
  color: white;
  font-size: 14px;
}

.my-custom-toast-class-orange .toast-text {
  color: white;
  font-size: 14px;
}
.my-custom-toast-class-blue-bodrer{
  border: 3px solid #0070C0;
  background-color: white;
}
.my-custom-toast-class-blue-bodrer .toastr-title-span{
  color: #0070C0;
}
.my-custom-toast-class-blue-bodrer .primary-color {
  color: #0070C0 !important;
}
.my-custom-toast-class-blue-bodrer .primary-color:hover {
  color: #0070C0 !important;
}
.my-custom-toast-class-blue-bodrer .mt-1{
  margin-top: 8px !important;
}
.my-custom-toast-class-blue-bodrer .toast-rfq-num span{
  font-size: 15px;
  font-weight: bold;
}
.my-custom-toast-class-blue-bodrer .toast-time span{
  font-size: 15px;
  /*font-weight: bold;*/
}
.my-custom-toast-class-blue-bodrer .toast-header-wrapper span{
  font-size: 15px;
  font-weight: 900;
}
.my-custom-toast-class-blue-bodrer .toast-header-buttons .dismiss-all{
  color: #0070C0;
  font-size: 14px;
}
.my-custom-toast-class-blue-bodrer .toast-header-buttons .close-toast{
  color: #0070C0;
}

.my-custom-toast-class-blue-bodrer .toast-text {
  color: black;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
}
.my-custom-toast-class-blue-bodrer .toast-text-ancillary{
  font-weight: 900;
  color: #0070C0;
}
.my-custom-toast-class-blue{
  background-color: #0070C0;
}
.my-custom-toast-class-blue .primary-color {
  color: white;
}
.my-custom-toast-class-blue .primary-color:hover {
  color: white;
}
.my-custom-toast-class-blue .mt-1{
  margin-top: 8px !important;
}
.my-custom-toast-class-blue .toast-rfq-num span{
  font-size: 15px;
  font-weight: bold;
}
.my-custom-toast-class-blue .toast-time span{
  font-size: 15px;
  /*font-weight: bold;*/
}
.my-custom-toast-class-blue .toast-header-wrapper span{
  font-size: 15px;
  font-weight: 900;
}
.my-custom-toast-class-blue .toast-header-buttons .dismiss-all{
  color: white;
  font-size: 14px;
}

.my-custom-toast-class-blue .toast-text {
  color: white;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
}
/*.my-custom-toast-class-blue .toast-text-ancillary{*/
/*  font-weight: 900;*/
/*  color: white;*/
/*}*/

.my-custom-toast-class button {
  color: #d3d3d3;
  font-size: 14px;
}

.my-custom-toast-class button:hover,
.my-custom-toast-class .close-toast:hover {
  color: white;
}

.my-custom-toast-class .close-toast {
  font-size: 20px;
  color: #d3d3d3;
}

.my-custom-toast-class .toast-text {
  margin-top: 10px;
  font-size: 13px;
}
.my-custom-toast-order-cancelled{
  background: #f8f8f8;
  border: 2px solid red;
}
.my-custom-toast-order-cancelled .primary-color {
  color: red !important;
}
.my-custom-toast-order-cancelled .primary-color:hover {
  color: red !important;
}
.my-custom-toast-order-cancelled .toast-header-wrapper{
  color: red;
  font-weight: bold;
}
.my-custom-toast-order-cancelled .dismiss-all, .my-custom-toast-order-cancelled .close-toast{
  color: red !important;
}
.my-custom-toast-order-cancelled .toast-name{
  color: black;
  font-weight: 15px;
  margin-bottom: 8px;
}
.my-custom-toast-order-cancelled .toast-text{
  color: black;
  font-size: 15px;
  /*font-weight: bold;*/

}
</style>

<style lang="scss">
.wrapper {
  visibility: hidden;
  opacity: 0;
  transition: opacity 100ms ease-out;
  transition-delay: 200ms;
}

.wrapper.is-visible,
.wrapper.theme-repairer,
.wrapper.theme-supplier,
.wrapper.theme-home {
  visibility: visible;
  opacity: 1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
