<script>

export default {
  name: "AuthByCode",
  data() {
    return {
      isProcessing: false,
      authCode: null,
    };
  },
  created() {
    console.log('!!!CREATED');
    if (this.$route && this.$route.params && this.$route.params.authCode && this.$route.params.authCode != '') {
      this.authCode = this.$route.params.authCode;
    }
  },
  mounted() {
    console.log('!!!MOUNTED');
    if (this.authCode && this.authCode != null && !this.isProcessing) {
      console.log('!!!!!!', this.authCode);
      this.isProcessing = true;
      this.loginByCode(this.authCode);
    }
  },
  methods: {
    auth () {
      this.$store.dispatch("auth/successLogin").then(() => {
        // let currentPath = this.$router.currentRoute.path;
        // if(currentPath==='/login') {
          if (this.$store.getters['currentUser/isUserTypeRepairer']) {
            this.$router.push('/r').then(() => {
              window.scrollTo(0, 0)
            });
          } else if (this.$store.getters['currentUser/isUserTypeSupplier']) {
            this.$router.push('/s').then(() => {
              window.scrollTo(0, 0)
            });
          }
        // }
      });
    },
    loginByCode(authCode) {
      if (authCode) {
        NProgress.start();
        this.$store.dispatch('auth/loginByCode', {authCode})
            .then((response) => {
              this.authCode = null;
              if (response && response.data && response.data._status) {
                this.auth();
                NProgress.done();
              } else {
                NProgress.done();
                this.$router.push('/login')
              }
            })
            .catch(() => {
              NProgress.done();
              this.$router.push('/login');
            });
      }
    },
  }
};
</script>

<template>
  <div class=""></div>
</template>


